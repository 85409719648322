import tw from "tailwind-styled-components";

export const FaqsModuleWrapper = tw.div`
  flex
  flex-col
  gap-6
  bg-gray-100
  rounded-2xl
  pt-8
  px-6
  pb-10

  desktop:p-0
  desktop:bg-white
  desktop:max-w-[700px]
  desktop:mx-auto
  desktop:gap-4
`;

export const FaqsModuleTitle = tw.h3`
  text-3xl
  text-center
  border-b
  border-gray-200
  pb-8

  desktop:pb-10
`;

export const LinkWrapper = tw.div`
  text-center

  desktop:mt-4
`;
