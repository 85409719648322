import dynamic from "next/dynamic";
import { Section } from "..";
import {
  SectionTitle,
  FaqsModuleWrapper,
  FaqsModuleTitle,
  LinkWrapper,
} from "./styles";
import { usePageData } from "../../../lib/page-components/page-data-provider";
import { useState } from "react";

const Link = dynamic(() => import("../../elements/link/link"));
const FaqsQuestion = dynamic(() =>
  import("../../elements/faqs-question/faqs-question")
);

export const FaqsModule = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  title,
  useCommonFaqs,
  specificFaqsQuestions,
  link,
  displaySetting

}) => {
  const { faqsCommon } = usePageData();
  const [commonQuestions, setCommonQuestions] = useState([]);

  useState(() => {
    if (faqsCommon) setCommonQuestions(faqsCommon.faqsQuestions);
  }, []);

  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation} 
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <FaqsModuleWrapper>
        {title && <FaqsModuleTitle>{title}</FaqsModuleTitle>}
        {useCommonFaqs &&
          commonQuestions.map((question) => (
            <FaqsQuestion {...question} key={question.id} />
          ))}
        {specificFaqsQuestions.map((question) => (
          <FaqsQuestion {...question} key={question.id} />
        ))}
        {link && (
          <LinkWrapper>
            <Link type="text" {...link} />
          </LinkWrapper>
        )}
      </FaqsModuleWrapper>
    </Section>
  );
};

export default FaqsModule;
